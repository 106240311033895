import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAuth from '@pages/auth/lib/useAuth';
import { alignToCenter, ContainerColumn } from '@components/styled';
import { ReactComponent as EmailIcon } from '@icons/wolfkit-light/envelope-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { ButtonContainer as ButtonContainerComponent, AuthButton, Link as LinkComponent, ButtonDivider, BinanceIcon, } from '../../../../components/shared';
import EntryImage from '../../../../components/InitialImage';
import FormWrapper from '../../../../components/FormWrapper';
const Container = alignToCenter(ContainerColumn);
const InitialStepContainer = styled.div(() => ({
    display: 'flex',
    width: 'inherit',
    height: 'inherit',
}));
const EntryImageContainer = styled.div(() => ({
    flex: 1,
    width: 'inherit',
}));
const AuthFormContainer = styled(Container)(() => ({
    flex: 1,
    height: 'inherit',
}));
const ButtonContainer = styled(ButtonContainerComponent)(props => ({
    flexDirection: 'column',
    gap: props.theme.spacing_sizes.xm,
    maxWidth: 344,
}));
const Initial = () => {
    const { t } = useTranslation();
    const { openEmailForm, openSignIn } = useAuth();
    const { goNext } = useWizard();
    const goToEmailForm = () => {
        openEmailForm();
        goNext();
    };
    return (_jsxs(InitialStepContainer, { children: [_jsx(EntryImageContainer, { children: _jsx(EntryImage, {}) }), _jsx(AuthFormContainer, { children: _jsx(FormWrapper, { titleText: t('auth.sign_up.title', { ns: 'common' }), renderInnerForm: (_jsxs(ButtonContainer, { children: [_jsx(AuthButton, { startIcon: _jsx(Icon, { IconComponent: BinanceIcon, keepOriginalColor: true }) }), _jsx(ButtonDivider, { children: t('auth.sign_up.steps.initial.divider_text', { ns: 'common' }) }), _jsx(AuthButton, { startIcon: _jsx(Icon, { IconComponent: EmailIcon }), onClick: goToEmailForm, children: t('auth.sign_up.buttons.email', { ns: 'common' }) })] })), renderLinks: (_jsxs(_Fragment, { children: [t('auth.sign_up.switch_text', { ns: 'common' }), _jsx(LinkComponent, { onClick: openSignIn, underline: 'hover', children: t('auth.sign_up.switch_link_text', { ns: 'common' }) })] })) }) })] }));
};
export default Initial;
